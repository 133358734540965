import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from './Login';
import Dashboard from './Dashboard';
import AdminDashboard from './AdminDashboard';
import { validateToken } from './api'; // API funktsioon, mis valideerib tokeni

// Kaitstud marsruudi komponent
function ProtectedRoute({ children, isLoggedIn, role, requiredRole }) {
  // Kui kasutaja pole sisse logitud, suuname /login lehele
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // Kui requiredRole on määratud ja kasutaja roll ei ühti, suuname avalehele
  if (requiredRole && role !== requiredRole) {
    return <Navigate to="/" replace />;
  }

  // Muul juhul tagastame children (komponendid, mis sees määratud)
  return children;
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Sisselogimise haldus
  const handleLogin = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
    setIsLoggedIn(true);
  };

  // Väljalogimise haldus
  const handleLogout = () => {
    if (window.confirm('Kas oled kindel, et soovid välja logida?')) {
      setToken(null);
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      setUserRole(null);
    }
  };

  // Kontrollime, kas token on salvestatud (localStorage) ja valideerime
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setIsLoading(true);
      validateToken(savedToken)
        .then((response) => {
          setToken(savedToken);
          setUserRole(response.data.user.role);
          setIsLoggedIn(true);
        })
        .catch((error) => {
          console.error('Token valideerimine ebaõnnestus:', error.response?.data);
          setErrorMessage('Valideerimine ebaõnnestus. Palun logi uuesti sisse.');
          handleLogout();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <div className="container text-center mt-5">Laadimine...</div>;
  }

  return (
    <Router>
      <div className="container mt-4">
        {/* Viga, kui token on vale või aegunud */}
        {errorMessage && (
          <div className="alert alert-danger text-center">{errorMessage}</div>
        )}
      </div>

      <Routes>
        {/* LOGIN TEE: ainult siis nähtav, kui pole sisse logitud */}
        {!isLoggedIn && (
          <Route
            path="/login"
            element={<Login onLogin={handleLogin} />}
          />
        )}

        {/* 
          "/" teel laeme kaitstud komponendi: Dashboard 
          (edasi laeb seesmised teed, nagu /uploads, /upload-study jne) 
        */}
        <Route
          path="/*"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={userRole}
            >
              <Dashboard
                token={token}
                onLogout={handleLogout}
                userRole={userRole}
              />
            </ProtectedRoute>
          }
        />

        {/* AdminDashboard näidis, kui on eraldi leht */}
        {/* 
          Kui AdminDashboard on juba Dashboard sees, võib selle eemaldada. 
        */}
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute
              isLoggedIn={isLoggedIn}
              role={userRole}
              requiredRole="admin"
            >
              <AdminDashboard token={token} onLogout={handleLogout} />
            </ProtectedRoute>
          }
        />

        {/* Fallback teele, kui midagi ei sobi */}
        <Route
          path="*"
          element={<Navigate to={isLoggedIn ? "/" : "/login"} replace />}
        />
      </Routes>
    </Router>
  );
}

export default App;
