import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function StudiesCount({ token }) {
    const [allStats, setAllStats] = useState([]); // Kõigi kasutajate statistika
    const [error, setError] = useState(''); // Vigade logimine
    const navigate = useNavigate(); // React Routeri navigeerimise funktsioon

    // Funktsioon: Kõigi kasutajate statistika toomine
    useEffect(() => {
        const fetchAllStats = async () => {
            try {
                const response = await axios.get('https://helikon.technology:5000/studies-count/stats', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log('Andmed serverist:', response.data); // Kontrollime, kas andmed tulevad
                setAllStats(response.data);
            } catch (err) {
                setError('Statistika laadimine ebaõnnestus');
                console.error('Error fetching all stats:', err.response || err.message);
            }
        };
        fetchAllStats();
    }, [token]);

    return (
        <div>
            <h3>Kõigi kasutajate statistika</h3>

            {/* Tabel andmetega */}
            {allStats.length > 0 ? (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Kuu</th>
                            <th>Kasutaja</th>
                            <th>Uuringute arv</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allStats.map((stat, index) => (
                            <tr key={index}>
                                <td>{stat.month || 'Pole määratud'}</td>
                                <td>
                                    {/* Kasutaja nimele vajutades navigeeri kasutaja uuringute juurde */}
                                    <button
                                        className="btn btn-link p-0"
                                        onClick={() => navigate(`/user-ekg-studies/${stat.userId}`)}
                                    >
                                        {stat.userName || 'Tundmatu kasutaja'}
                                    </button>
                                </td>
                                <td>{stat.totalUploads || 'Puudub'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="text-center">Statistikat ei leitud.</p>
            )}

            {/* Veateade */}
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
}

export default StudiesCount;
